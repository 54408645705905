import React, {useState} from 'react';
import Checkout from './Pages/Checkout';
import Landing from './Pages/Landing';
import biz from './Assets/BIZ.svg';
import logo from './Assets/logo-ien.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import { 
  Footer
} from './Atoms';

function App() {
  const [openCheckout, setOpenCheckout] = useState();

  return (
    <div className="App">
      <div className="traced-header" />
      <img src={logo} className="App-logo" alt="logo" />
      {openCheckout && <Checkout plan={openCheckout} onCloseCheckout={setOpenCheckout} />}
      {!openCheckout && <Landing onOpenCheckout={setOpenCheckout} />}
      <Footer>
        <img src={biz} alt="Biz | simplesnologia" />
      </Footer>
    </div>
  );
}

export default App;
