import React, { useState } from 'react';
import { 
  Wrapper, Header, PriceTierCards, PriceTierCard, Badge,
  PriceTierHead, PriceTierPriceBlock, PriceTierPrice,
  BuyButtom, Benefits, BenefitsTitle, BenefitsWelcome,
  BenefitsList, FAQBox, FAQAccordion, AccordionItem,
  FAQTitle, FAQText, BoxSpacer, FaqDivider,
} from '../Atoms';

function Landing({ onOpenCheckout }) {
  const [accordionItems, setAccordionItems] = useState({
    AC1: false,
    AC2: false,
    AC3: false,
  });

  const toogleHeight = (item) => {
    const newACs = { ...accordionItems };
    
    if (newACs[item]) {
      newACs[item] = false;
    } else {
      newACs[item] = true;
    }

    setAccordionItems(newACs);
  }
  return (<>
    <Wrapper>
      <Header className="App-header">
        <h1>Seja uma das gentes que constroem uma Economia ao Natural.</h1>
        <p>Associe-se e faça parte, tornando-se uma pessoa benignadora de projetos e ações capazes de transformar vidas.</p>
      </Header>
      <PriceTierCards>
        <PriceTierCard>
          <BoxSpacer>
            <PriceTierHead>
              <p>Contribuição IEN1+</p>
            </PriceTierHead>
            <PriceTierPriceBlock>
              <PriceTierPrice inputColor="#2C8EB6">R$ 49</PriceTierPrice>
              <p>por mês</p>
            </PriceTierPriceBlock>
            <p>Nosso contribuição de entrada.</p>
            <BuyButtom inputColor="#2C8EB6" onClick={() => onOpenCheckout('IEN1+')}>Quero contribuir com esse...</BuyButtom>
          </BoxSpacer>
          <Benefits>
            <BenefitsTitle>BENEFÍCIOS</BenefitsTitle>
            <BenefitsWelcome>Esse é o nosso kit de boas-vindas...</BenefitsWelcome>
            <BenefitsList checkType="check1">
              <li>Conteúdos quinzenais exclusivos</li>
              <li>Rodas de significado mensais conduzidas por Roberto Tranjan</li>
            </BenefitsList>
          </Benefits>
        </PriceTierCard>

        <PriceTierCard>
          <BoxSpacer>
            <PriceTierHead>
              <p>Contribuição IEN2+</p>
              <Badge>Mais Popular</Badge>
            </PriceTierHead>
            <PriceTierPriceBlock>
              <PriceTierPrice inputColor="#F79633">R$ 99</PriceTierPrice>
              <p>por mês</p>
            </PriceTierPriceBlock>
            <p>Nosso contribuição mais popular.</p>
            <BuyButtom  inputColor="#F79633" onClick={() => onOpenCheckout('IEN2+')}>Quero contribuir com esse...</BuyButtom>
          </BoxSpacer>
          <Benefits>
            <BenefitsTitle>BENEFÍCIOS</BenefitsTitle>
            <BenefitsWelcome>Esse é o nosso kit de boas-vindas...</BenefitsWelcome>
            <BenefitsList checkType="check2">
              <li>Conteúdos quinzenais exclusivos</li>
              <li>Experiências presenciais guiadas com pessoas e negócios que vivem a Economia ao Natural</li>
              <li>Rodas de significado mensais conduzidas por Roberto Tranjan</li>
              <li><b>1 ingressos</b> para o evento anual de inspiração da Economia ao Natural</li>
              <li>1 camiseta exclusiva de associado</li>
              <li>1 exemplar do livro <b>Pedaços de Brasil que dão certo</b></li>
            </BenefitsList>
          </Benefits>
        </PriceTierCard>

        <PriceTierCard>
          <BoxSpacer>
            <PriceTierHead>
              <p>Contribuição IEN3+</p>
            </PriceTierHead>
            <PriceTierPriceBlock>
              <PriceTierPrice inputColor="#5CC6D0">R$ 199</PriceTierPrice>
              <p>por mês</p>
            </PriceTierPriceBlock>
            <p>Nosso contribuição mais potente.</p>
            <BuyButtom inputColor="#5CC6D0" onClick={() => onOpenCheckout('IEN3+')}>Quero contribuir com esse...</BuyButtom>
          </BoxSpacer>
          <Benefits>
            <BenefitsTitle>BENEFÍCIOS</BenefitsTitle>
            <BenefitsWelcome>Esse é o nosso kit de boas-vindas...</BenefitsWelcome>
            <BenefitsList checkType="check3">
              <li>Conteúdos quinzenais exclusivos</li>
              <li>Experiências presenciais guiadas com pessoas e negócios que vivem a Economia ao Natural</li>
              <li>Rodas de significado mensais conduzidas por Roberto Tranjan com 3 convidados</li>
              <li><b>5 ingressos</b> para o evento anual de inspiração da Economia ao Natural</li>
              <li>1 camiseta exclusiva de associado</li>
              <li>1 exemplar do livro <b>Pedaços de Brasil que dão certo</b></li>
              <li>1 exemplar do livro <b>O velho e o menino</b></li>
            </BenefitsList>
          </Benefits>
        </PriceTierCard>
      </PriceTierCards>
    </Wrapper>
    <FaqDivider><span>Tem dúvidas?</span></FaqDivider>
    <Wrapper>
      <FAQBox>
        <h2>Perguntas Frequentes</h2>
        <FAQAccordion>
          <AccordionItem>
            <FAQTitle onClick={() => toogleHeight('AC1')}>
              A minha contribuição ajudará em quê?
              <i className={`bi bi-chevron-${accordionItems.AC1 ? 'down' : 'up'}`}></i>
            </FAQTitle>
            <FAQText className={accordionItems.AC1 ? '' :  'd-none'}>
              Sua contribuição nos ajudará em projetos e ações desenvolvidas pelo Instituto Economia ao Natural que entrelaçam pessoas, negócios, arte, poesia e Natureza.<br /><br />Dentre eles, conta com o programa SouPaideia destinado a jovens entre 15 e 21 anos que precisam descobrir o seu lugar no mundo.
            </FAQText>
          </AccordionItem>
          <AccordionItem>
          <FAQTitle onClick={() => toogleHeight('AC2')}>
              Minha contribuição irá consumir o limite do meu cartão de crédito?
              <i className={`bi bi-chevron-${accordionItems.AC2 ? 'down' : 'up'}`}></i>
            </FAQTitle>
            <FAQText className={accordionItems.AC2 ? '' :  'd-none'}>
              Pode ficar tranquilo: a sua contribuição não irá consumir o limite do seu cartão de crédito. O débito de sua contribuição será feita mensalmente sem comprometer seu limite.
            </FAQText>
          </AccordionItem>
          <AccordionItem>
            <FAQTitle onClick={() => toogleHeight('AC3')}>
              Eu posso cancelar minha contribuição a qualquer momento?
              <i className={`bi bi-chevron-${accordionItems.AC3 ? 'down' : 'up'}`}></i>
            </FAQTitle>
            <FAQText className={accordionItems.AC3 ? '' :  'd-none'}>
              Pode sim. Para isso é necessário entrar em contato com o e-mail financeiro@economiaaonatural.org.br.
            </FAQText>
          </AccordionItem>
        </FAQAccordion>
      </FAQBox>
    </Wrapper>
  </>);
}

export default Landing;
