import React, {useState, useEffect, useRef} from 'react';
import {
  CloseButton, Title, InfoBox, InfoHeader,
  InfoTitle, InfoPrice, InfoDescription,
  Divider, AddressBox, FormBox, LoadingBox,
  LoadingWrapper, SuccessWrapper, SuccessBox,
  IconInput
 } from './CheckoutAtoms';
import InputMask from 'react-input-mask';
import { ColorRing } from 'react-loader-spinner'
import Moment from 'moment';
import ConsultaCep from '../Services/BrasilAPI';
import {createUser} from '../Services/AsaasAPI';
import CreditCard from '../Components/CreditCard';

const plans = [{
  name: 'IEN1+',
  price: 49,
  description: 'Conteúdos quinzenais exclusivos, Rodas de significado mensais conduzidas por Roberto Tranjan.',
  asaasDescription: 'Contribuição IEN1+'
},{
  name: 'IEN2+',
  price: 99,
  description: 'Conteúdos quinzenais exclusivos, Experiências presenciais guiadas com pessoas e negócios que vivem a Economia ao Natural, Rodas de significado mensais conduzidas por Roberto Tranjan, 1 ingresso para o evento anual de inspiração da Economia ao Natural, 1 camiseta exclusiva de associado, 1 exemplar do livro Pedaços de Brasil que dão certo.',
  asaasDescription: 'Contribuição IEN2+'
},{
  name: 'IEN3+',
  price: 199,
  description: 'Conteúdos quinzenais exclusivos, Experiências presenciais guiadas com pessoas e negócios que vivem a Economia ao Natural, Rodas de significado mensais conduzidas por Roberto Tranjan com 3 convidados, 5 ingressos para o evento anual de inspiração da Economia ao Natural, 1 camiseta exclusiva de associado, 1 exemplar do livro Pedaços de Brasil que dão certo, 1 exemplar do livro O velho e o menino.',
  asaasDescription: 'Contribuição IEN3+'
}];

function Checkout({ plan, onCloseCheckout }) {
  const [address, setAddress] = useState({});
  const [holderAddress, setHolderAddress] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});
  const [cardOwnerHolder, setCardOwnerHolder] = useState();
  const [ip, setIP] = useState("");
  const [isloading, setIsLoading] = useState();
  const [showError, setShowError] = useState();
  const [showSuccess, setShowSuccess] = useState();
  const [disableSubmit, setDisableSubmit] = useState(true);
  
  const [userForm, setUserForm] = useState({});
  const [holderForm, setHolderForm] = useState({});

  const validateForm = () => {
    if (
      userForm.name &&
      userForm.cpf &&
      userForm.email &&
      userForm.mobilePhone &&
      userForm.addressNumber &&
      address.cep &&
      userForm.shirt &&
      userForm.creditCard &&
      userForm.creditCard.number &&
      userForm.cardExpires &&
      userForm.cvc &&
      userForm.cardName && 
      (!cardOwnerHolder || cardOwnerHolder && 
        holderForm.name &&
        holderForm.cpf &&
        holderForm.mobilePhone &&
        holderForm.email &&
        holderAddress.cep &&
        holderForm.addressNumber
      )
    ) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  };

  const updateUserForm = (field, value) => {
    let newForm = {
      ...userForm,
    }

    newForm[field] = value;

    setUserForm(newForm);
    validateForm();
  };

  const updateHolderForm = (field, value) => {
    let newForm = {
      ...holderForm,
    }

    newForm[field] = value;

    setHolderForm(newForm);
    validateForm();
  };

  const getIPData = async () => {
    const response = await fetch("https://api.ipify.org/?format=json");
    const res = await response.json();
    setInput1Focus();

    setIP(res.ip);
  };

  useEffect(() => {
    getIPData();
    if (plan !== 'Custom') {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (plan) {
      const x = plans.find(i => i.name === plan);

      setSelectedPlan(x)
    }
  }, [plan]);

  useEffect(() => {
    validateForm();
  }, [cardOwnerHolder]);

  const UseFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
  
    return [ htmlElRef,  setFocus ] 
  }

  const [input1Ref, setInput1Focus] = UseFocus()

  return (
    <div>
      {showError && (
        <div className="alert alert-danger" style={{margin: '0 10%', position: 'fixed', zIndex: 9999, top: '10px', right: '10px'}} role="alert">
          Ocorreu um erro ao processar sua assinatura, por favor tente novamente mais tarde.
        </div>
      )}
      <div className={`CheckoutBox ${(isloading || showSuccess) && 'd-none'}`}>
        <CloseButton onClick={() => onCloseCheckout()}>X</CloseButton>
        <Title>Informações da sua contribuição</Title>
        <InfoBox>
          <InfoHeader>
            <InfoTitle>Contribuição {plan}</InfoTitle>
            <InfoPrice><b>R$ {selectedPlan.price}</b> por mês</InfoPrice>
          </InfoHeader>
          <InfoDescription>
            {selectedPlan ? selectedPlan.description : ''}
          </InfoDescription>
        </InfoBox>
        <Divider></Divider>
        <FormBox>
          <h3>Suas informações</h3>
          <form style={{margin: '16px 0'}}>
            <div className="row">
              <div className="form-group col-md-4">
                <label for="exampleInputPassword1">CPF</label>
                <InputMask className="form-control" placeholder="000.000.000-00" mask="999.999.999-99" onChange={(e) => {
                  updateUserForm('cpf', e.target.value)
                }} maskChar="" >
                  {(inputProps) => <input {...inputProps} ref={input1Ref} />}
                </InputMask>
              </div>
              <div className="form-group col-md-8">
                <label for="namee">Nome completo</label>
                <input type="text" placeholder="Nome completo" className="form-control" id="namee" onChange={(e) => {updateUserForm('name', e.target.value)}} />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4">
                <label for="exampleInputCel">Celular / Whatsapp</label>
                <IconInput>
                  <i className="bi bi-telephone"></i>
                  <InputMask className="form-control" placeholder="11 99999-9999" mask="99 99999-9999" onChange={(e) => {updateUserForm('mobilePhone', e.target.value)}} maskChar="" />
                </IconInput>
              </div>
              <div className="form-group col-md-8">
                <label for="exampleInputEmail1">Email</label>
                <IconInput>
                  <i className="bi bi-envelope"></i>
                  <input type="email" placeholder="eu@email.com" className="form-control" id="exampleInputEmail1" onChange={(e) => {updateUserForm('email', e.target.value)}} />
                </IconInput>
              </div>
            </div>
          </form>
          <Divider style={{marginBottom: '16px'}}></Divider>
          <h3>Seu kit de boas-vindas</h3>
          <form style={{padding: '16px 0'}}>
            <div className="row">
              <div className="form-group col-md-4">
                <label for="exampleInputPassword1">CEP</label>
                <IconInput>
                  <i className="bi bi-geo-fill"></i>
                  <InputMask className="form-control" mask="99999-999" placeholder="00000-000" onChange={async (e) => {
                    if (e.target.value.length === 9) {
                      const addressInfo = await ConsultaCep(e.target.value);

                      setAddress(addressInfo);
                    }

                  }} maskChar="" />
                </IconInput>
              </div>
              <div className="form-group col-md-8">
              <AddressBox>
                <p>{address.street}</p>
                <p>{address.neighborhood} - {address.city} - {address.state}</p>
              </AddressBox>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4">
                <label for="exampleInputCel">Número</label>
                <input type="tel" className="form-control" placeholder="000"  onChange={(e) => {updateUserForm('addressNumber', e.target.value)}}/>
              </div>
              <div className="form-group col-md-8">
                <label for="comp">Complemento</label>
                <input type="text" placeholder="Apto 00000 / Casa 00000" className="form-control" id="comp" onChange={(e) => {updateUserForm('complement', e.target.value)}} />
              </div>
            </div>
            <div className="form-group">
              <label>Qual o tamanho de camiseta que você gostaria?</label>
            </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" onClick={(e) =>{updateUserForm('shirt', e.target.value)}} name="camisetas" id="inlineRadio1" value="P" />
                <label className="form-check-label" for="inlineRadio1">P</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" onClick={(e) =>{updateUserForm('shirt', e.target.value)}} name="camisetas" id="inlineRadio2" value="M" />
                <label className="form-check-label" for="inlineRadio2">M</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" onClick={(e) =>{updateUserForm('shirt', e.target.value)}} name="camisetas" id="inlineRadio3" value="G" />
                <label className="form-check-label" for="inlineRadio3">G</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" onClick={(e) =>{updateUserForm('shirt', e.target.value)}} name="camisetas" id="inlineRadio4" value="GG" />
                <label className="form-check-label" for="inlineRadio4">GG</label>
              </div>
            
          </form>
          <Divider style={{marginBottom: '16px'}}></Divider>
          <h3>Seu pagamento</h3>
          <form style={{padding: '16px 0'}}>
            <div className="row">
              <div className="form-group col-md-4">
                <label>Número do cartão de crédito</label>
                <CreditCard 
                  onUpdate={(e) => {
                    updateUserForm('creditCard', e);
                    validateForm();
                  }}
                />
              </div>
              <div className="form-group col-md-4">
                <label for="card_name">Nome do titular <em>(como está gravado no cartão)</em></label>
                <input type="text" placeholder="Nome impresso no cartão" className="form-control" id="card_name" onChange={(e) => {updateUserForm('cardName', e.target.value)}} />
              </div>
              <div className="form-group col-md-2 col-6">
                <label for="cardExpires">Validade</label>
                <InputMask className="form-control" placeholder="MM / AA" mask="99 / 99" onChange={(e) => {updateUserForm('cardExpires', e.target.value)}} maskChar="" />
              </div>
              <div className="form-group col-md-2 col-6">
                <label for="cvc">CVC</label>
                <InputMask className="form-control" placeholder="***" mask="999" onChange={(e) => {updateUserForm('cvc', e.target.value)}} maskChar="*" />
              </div>
            </div>
          </form>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => {
              setCardOwnerHolder(!cardOwnerHolder);
            }} />
            <label className="form-check-label" for="flexCheckDefault">
              O cartão de crédito é de outro titular que não sou eu
            </label>
          </div>
          {cardOwnerHolder && 
              <div style={{paddingTop: '20px'}}>
              <h3>Informações do titular</h3>
              <form style={{margin: '16px 0'}}>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label for="hexampleInputPassword1">CPF</label>
                    <InputMask className="form-control" placeholder="000.000.000-00" mask="999.999.999-99" onChange={(e) => {
                      updateHolderForm('cpf', e.target.value)
                    }} maskChar="" />
                  </div>
                  <div className="form-group col-md-8">
                    <label for="hnamee">Nome completo</label>
                    <input type="text" placeholder="Nome completo" className="form-control" id="hnamee" onChange={(e) => {updateHolderForm('name', e.target.value)}} />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label for="hexampleInputCel">Celular / Whatsapp</label>
                    <IconInput>
                      <i className="bi bi-telephone"></i>
                      <InputMask className="form-control" placeholder="11 99999-9999" mask="99 99999-9999" onChange={(e) => {updateHolderForm('mobilePhone', e.target.value)}} maskChar="" />
                    </IconInput>
                  </div>
                  <div className="form-group col-md-8">
                    <label for="hexampleInputEmail1">Email</label>
                    <IconInput>
                      <i className="bi bi-envelope"></i>
                      <input type="email" placeholder="eu@email.com" className="form-control" id="hexampleInputEmail1" onChange={(e) => {updateHolderForm('email', e.target.value)}} />
                    </IconInput>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label for="exampleInputPassword1">CEP</label>
                      <IconInput>
                        <i className="bi bi-geo-fill"></i>
                        <InputMask className="form-control" mask="99999-999" placeholder="00000-000" onChange={async (e) => {
                          if (e.target.value.length === 9) {
                            const addressInfo = await ConsultaCep(e.target.value);

                            setHolderAddress(addressInfo);
                          }

                        }} maskChar="" />
                      </IconInput>
                    </div>
                    <div className="form-group col-md-8">
                    <AddressBox>
                      <p>{holderAddress.street}</p>
                      <p>{holderAddress.neighborhood} - {holderAddress.city} - {holderAddress.state}</p>
                    </AddressBox>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label for="exampleInputCel">Número</label>
                      <input type="tel" className="form-control" placeholder="000"  onChange={(e) => {updateHolderForm('addressNumber', e.target.value)}}/>
                    </div>
                    <div className="form-group col-md-8">
                      <label for="comp">Complemento</label>
                      <input type="text" placeholder="Apto 00000 / Casa 00000" className="form-control" id="comp" onChange={(e) => {updateHolderForm('complement', e.target.value)}} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          }
        </FormBox>
        <Divider></Divider>
        <div style={{padding: '16px'}} className="d-grid gap-2">
          <button type="submit" disabled={disableSubmit} className="btn btn-success" onClick={async () => {
            setIsLoading(true)

            const expires = userForm.cardExpires.split(' / ')

            const creditCardHolderInfo = cardOwnerHolder ? {
              name: holderForm.name,
              email: holderForm.email,
              cpfCnpj: holderForm.cpf,
              postalCode: holderAddress.cep,
              addressNumber: holderForm.addressNumber,
              addressComplement: holderForm.complement,
              phone: holderForm.mobilePhone
            } : {
              name: userForm.name,
              email: userForm.email,
              cpfCnpj: userForm.cpf,
              postalCode: address.cep,
              addressNumber: userForm.addressNumber,
              addressComplement: userForm.complement,
              phone: userForm.mobilePhone
            }

            const resp = await createUser({
              user: {
                name: userForm.name,
                cpfCnpj: userForm.cpf,
                email: userForm.email,
                mobilePhone: userForm.mobilePhone,
                address: address.street,
                addressNumber: userForm.addressNumber,
                complement: userForm.complement,
                province: address.neighborhood,
                postalCode: address.cep,
                notificationDisabled: true,
                observations: (userForm.shirt ? `camiseta:${userForm.shirt}` : "")
              },
              payment: {
                billingType: 'CREDIT_CARD',
                value: selectedPlan.price,
                nextDueDate: Moment().format("YYYY-MM-DD"),
                cycle: 'MONTHLY',
                description: selectedPlan.asaasDescription,
                creditCard: {
                  holderName: userForm.cardName,
                  number: userForm.creditCard.number.replaceAll(' ', ''),
                  expiryMonth: expires[0],
                  expiryYear: `20${expires[1]}`,
                  ccv: userForm.cvc,
                },
                creditCardHolderInfo,
                remoteIp: ip
              }
            })

            if (resp.success) {
              setIsLoading();
              setShowSuccess(true);
            } else {
              setIsLoading();
              setShowError(true);
              setTimeout(() => {
                setShowError();
              }, 5000)
            }


          }}>Confirmar minha contribuição...</button>
        </div>
      </div>
      <LoadingWrapper className={`${!isloading && 'd-none'}`}>
        <LoadingBox>
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#47CD89', '#47CD89', '#47CD89', '#47CD89', '#47CD89']}
          />
          <p>Processando sua contribuição...</p>
        </LoadingBox>
      </LoadingWrapper>
      <SuccessWrapper className={`${!showSuccess && 'd-none'}`}>
        <SuccessBox>
          <h2>Muito obrigado!!!</h2>
          <p>Sua contribuição nos ajudará a benignar ainda mais vidas!</p>
        </SuccessBox>
      </SuccessWrapper>
    </div>
  );
}

export default Checkout;
